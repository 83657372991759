/* eslint-disable import/prefer-default-export */
import axios from '../../services/axiosInstance';
import errorHandler from '../../services/error/handler';

const storeId = process.env.NEXT_PUBLIC_STORE_ID;

const get = async (token) => {
  try {
    const response = await axios.get(`/account/wish_products`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });

    return {
      success: true,
      data: response?.data
    };
  } catch (err) {
    const message = errorHandler(err);

    return {
      success: false,
      error: message
    };
  }
};

const add = async (productId) => {
  try {
    const response = await axios.post(
      `/products/${productId}/add_to_wish_list?code=${storeId}`
    );

    return {
      success: true,
      data: response.data
    };
  } catch (err) {
    const message = errorHandler(err);

    return {
      success: false,
      error: message
    };
  }
};

const remove = async (productId) => {
  try {
    const response = await axios.post(
      `/products/${productId}/remove_from_wish_list`
    );

    return {
      success: true,
      data: response.data
    };
  } catch (err) {
    const message = errorHandler(err);

    return {
      success: false,
      error: message
    };
  }
};

export default {
  get,
  add,
  remove
};
