import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';
import PropTypes from 'prop-types';
// import { updateMap } from '@/redux/map/mapActions';
import styles from './storeBranchItem.module.scss';
import { setActive } from '@/redux/map/mapReducer';
// import useMetatagsData from '@/hooks/useMetatagsData';

const StoreBranchItem = (props) => {
  const { item, map } = props;
  // const { STORE_ID } = useMetatagsData();
  const { office_hours: officeHours, address } = item;

  const dispatch = useDispatch();
  const { active } = useSelector((state) => state.map);

  const itemMap = item.address.address1
    .toLowerCase()
    .concat(' ', item.address.address_number, ' ', 'vaypol')
    .replace(/\s/g, '_')
    .replace(/í/g, 'i');

  const handleClick = () => {
    dispatch(
      setActive({
        address: itemMap,
        lat: item.latitude,
        lng: item.longitude
      })
    );
    map?.current.scrollIntoView({
      behavior: 'smooth',
      block: 'center'
    });
  };

  const classButton = cn({
    [styles.button]: true,
    [styles.active]: itemMap === active
  });

  return (
    <button
      className={classButton}
      onClick={handleClick}
    >
      <ul className={styles.storeBranchList}>
        <li className={styles.item}>
          <span>
            {address.address1} {address.address_number}
          </span>
          <span>{address.address2}</span>
          <span>Tel: {address.phone}</span>
          <span>Horario de atención: {officeHours}</span>
        </li>
      </ul>
    </button>
  );
};

StoreBranchItem.propTypes = {
  map: PropTypes.object.isRequired,
  item: PropTypes.shape({
    office_hours: PropTypes.string,
    address: PropTypes.object,
    latitude: PropTypes.string,
    longitude: PropTypes.string
  }).isRequired
};

export default StoreBranchItem;
