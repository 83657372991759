/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useRef, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Button, Icon, Image, Text } from '@/components/atoms';
import styles from './cardPromoModal.module.scss';
import useFixBody from '@/hooks/useFixBody';
import useOutsideClick from '@/hooks/useOutsideClick';
import Chevron from '@/components/atoms/Chevron';
import parsePriceToNumber from '@/utils/parsePriceToNumber';

const CardPromosModal = (props) => {
  const { onClose } = props;
  const [selectedBox, setSelectedBox] = useState([]);
  const [openSelect, setOpenSelect] = useState(false);
  const [boxes, setBoxes] = useState({});
  const { cardPromos, price } = useSelector((state) => state.productDetail);
  const ref = useRef(null);
  const selectorRef = useRef(null);
  const modo = [
    { box: 'modo', installments: '1', interest_rate: '0.0' },
    { box: 'modo', installments: '3', interest_rate: '0.0' }
  ];
  useFixBody();
  useOutsideClick(ref, () => {
    onClose(false);
  });

  useEffect(() => {
    const _boxes = {};
    const CardPromosAux = [...cardPromos];
    CardPromosAux.forEach((element) => {
      const elemntAux = { ...element };
      if (!elemntAux.box.includes('-debit')) {
        if (_boxes[elemntAux.box]) {
          elemntAux.installments = parseInt(elemntAux?.installments, 10);
          _boxes[elemntAux.box].push(elemntAux);
        } else {
          _boxes[elemntAux.box] = [elemntAux];
        }
      }
    });
    setBoxes({ ..._boxes, modo });
  }, []);

  useEffect(() => {
    setSelectedBox(boxes.visa);
  }, [boxes]);

  const installmentItem = (i) => {
    const parse = (number) => {
      const string = new Intl.NumberFormat('de-DE', {
        style: 'currency',
        currency: 'ARS'
      }).format(number.toFixed(2));
      return string.split(',')[0].replace('ARS', '');
    };
    const productPrice = parsePriceToNumber(price);
    const interestRate = parseFloat(i.interest_rate);
    const interestFeed = productPrice * (interestRate / 100);
    const amountWithInterest = productPrice * (1 + interestRate / 100);
    const interestValue = amountWithInterest / i.installments;

    return (
      <div className={styles.installmentItem}>
        <Text
          textColor="var(--color-primary)"
          weight="bold"
          textSize="m"
          textStyle={{ marginBottom: '3px' }}
        >
          {i.installments} cuotas de ${parse(interestValue)}
        </Text>
        <Text textSize="xs">
          Precio total financiado de $
          {i.installments < 2 ? 0 : parse(amountWithInterest)}
        </Text>
        <Text textSize="xs">Intereses ${parse(interestFeed)}</Text>
        <Text textSize="xs">CFT: {interestRate}%</Text>
      </div>
    );
  };

  const handleToogleSelector = (open) => {
    if (open) {
      selectorRef.current.style.height = '70px';
    } else {
      selectorRef.current.style.height = `${selectorRef.current.scrollHeight}px`;
    }
    setOpenSelect(!open);
  };

  return (
    <div className={styles.backdrop}>
      <div
        ref={ref}
        className={styles.modal}
      >
        <div className={styles.title}>
          <div
            style={{
              flex: 1,
              display: 'flex',
              alignItems: 'flex-end',
              justifyContent: 'center'
            }}
          >
            <Text
              weight="bold"
              textSize="l"
              textStyle={{
                textAlign: 'center'
              }}
              textColor="var(--color-white-absolute)"
            >
              Promociones Bancarias
            </Text>
          </div>
        </div>
        <div
          style={{
            width: 20,
            height: 20,
            position: 'absolute',
            top: '10px',
            right: '10px'
          }}
        >
          <Button
            withOutPadding
            variant="icon"
            startIcon={
              <Icon
                type="close"
                color="white"
                width="0.8rem"
              />
            }
            onClick={() => onClose(false)}
          />
        </div>
        <div
          id="aca"
          ref={selectorRef}
          className={styles.boxSelector}
        >
          <Text>Medio de Pago</Text>
          <div className={styles.selectedBox}>
            <div>
              <Image
                alt={`${selectedBox?.[0]?.box} logo`}
                width="30"
                height="30"
                src={`/assets/credit-cards/${selectedBox?.[0]?.box}.svg`}
              />
            </div>

            <Button
              withOutPadding
              width="auto"
              variant="icon"
              startIcon={
                <Chevron
                  color="white"
                  open={!openSelect}
                  size={30}
                  timeOfRotate="0.2s"
                />
              }
              onClick={() => handleToogleSelector(openSelect)}
            />
          </div>
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              width: '100%',
              justifyContent: 'space-evenly'
            }}
          >
            {Object.keys(boxes).map((b) => {
              return (
                <Button
                  key={b}
                  withOutPadding
                  height=""
                  width="auto"
                  style={{ margin: '5px' }}
                  startIcon={
                    <Image
                      alt={`${b}logo`}
                      width="50"
                      height="50"
                      src={`/assets/credit-cards/${b}.svg`}
                    />
                  }
                  onClick={() => {
                    setSelectedBox(boxes[b]);
                    handleToogleSelector(openSelect);
                  }}
                />
              );
            })}
          </div>
        </div>
        <Text
          textSize="s"
          weight="semibold"
          textStyle={{ marginBottom: '20px' }}
        >
          {' '}
          Precio en 1 pago: ${price.split(',')[0]}
        </Text>
        <div className={styles.installmentList}>
          {selectedBox
            ?.sort((a, b) => {
              if (a.installments < b.installments) {
                return -1;
              }
              if (a.installments > b.installments) {
                return 1;
              }
              // a must be equal to b
              return 0;
            })
            .map((i) => installmentItem(i))}
        </div>
      </div>
    </div>
  );
};

CardPromosModal.propTypes = {
  onClose: PropTypes.func.isRequired
};

export default CardPromosModal;
