'use client';

import { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { BsCreditCard } from 'react-icons/bs';
import { FaYoutube } from 'react-icons/fa';
import { usePathname } from 'next/navigation';
import { Button, Icon, Link, Text } from '@/components/atoms';
import {
  ColorSelector,
  // QuantitySelector,
  SizeSelector,
  PunctuationReview,
  GeneralModal
} from '@/components/molecules';
import api from '@/api/cart';
import styles from './productInfo.module.scss';
import { addToCart, toggleCartModal } from '@/redux/cart/cartReducer';
import useDeviceType from '@/hooks/useDeviceType';
import useTrackingQueue from '@/hooks/useTrackingQueue';
import ClickandCollect from '../../ClickandCollectModal';
import createUUID from '@/utils/createUUID';
import ShareButton from '../../ShareButton';
import CardPromosModal from './CardPromoModal';

const BankPromotions = ({ setCardPromoModal }) => (
  <Button
    id="promos bancarias"
    onClick={() => setCardPromoModal(true)}
    withOutPadding
    style={{ justifyContent: 'flex-start' }}
    startIcon={
      <BsCreditCard
        size={20}
        color="var(--color-primary)"
      />
    }
    label="Ver promociones bancarias"
    textColor="var(--color-primary)"
  />
);

const Installments = ({ installments }) => (
  <Text
    colored
    variant="span"
    textSize="s"
    textStyle={{ marginBottom: '2rem' }}
    showSkeleton={Object.keys(installments).length === 0}
  >
    Pagá en {installments.quantity} cuotas sin interés de $
    {installments.value.split(',')[0]}
  </Text>
);

const Gender = ({ gender, sport }) => (
  <Text
    variant="h3"
    textSize="s"
    showSkeleton={!gender && !sport}
    colored
  >
    {gender} - {sport}
  </Text>
);

const Featured = ({ featured }) => (
  <div
    style={{
      background:
        !!featured?.background_color_left && !!featured?.background_color_right
          ? `linear-gradient(90deg, ${featured?.background_color_left}, ${featured?.background_color_right})`
          : featured?.background_color,
      color: featured?.text_color
    }}
    className={styles.badge}
  >
    {featured.name}
  </div>
);

const Sku = ({ sku }) => (
  <Text
    variant="span"
    textSize="xxs"
    showSkeleton={!sku}
    textColor="var(--color-grey-lighter)"
  >
    SKU {sku}
  </Text>
);

const Name = ({ name, color }) => (
  <Text
    variant="h1"
    textSize="xxl"
    textStyle={{ paddingRight: '2rem' }}
  >
    {name} - {color?.split('/').join(' / ')}
  </Text>
);

const Discount = ({ discount }) => (
  <Text
    variant="h3"
    textSize="l"
    showSkeleton={!discount}
    className={styles.discount}
  >
    {discount}% OFF
  </Text>
);

const StrikePrice = ({ strikePrice }) => (
  <Text
    strike
    variant="h2"
    textSize="m"
    weight="light"
    showSkeleton={!strikePrice}
  >
    ${strikePrice.split(',')[0]}
  </Text>
);

const Price = ({ price }) => (
  <Text
    textSize="xxl"
    weight="bolder"
    showSkeleton={!price}
  >
    ${price?.split(',')[0]}
  </Text>
);

const ProductInfo = ({
  handleSetProduct,
  color,
  colors,
  gender,
  id,
  idColor,
  name,
  price,
  sku,
  slug,
  business,
  closeModal,
  brand_name: brandName,
  strikePrice = '',
  discount = '',
  sport = '',
  installments = {},
  linkToDetail = false,
  variants = [],
  isModal = false,
  valorations = {},
  featured = null,
  youTubeLink = []
}) => {
  const dispatch = useDispatch();

  const pathname = usePathname();
  const storeId = useSelector((state) => state.store);
  const productDetail = useSelector((state) => state.productDetail);
  const [cardPromosModal, setCardPromoModal] = useState(false);
  const [showYTVideo, setShowYTVideo] = useState('');
  const eventId = createUUID();
  const { variantSelected } = productDetail;
  const { isDesktop } = useDeviceType();
  const [hidden, setHidden] = useState(false);
  const refInfo = useRef(null);
  const { executeEvent } = useTrackingQueue();
  const [availableButton, setAvailableButton] = useState({
    available: false,
    path: ''
  });
  const [loading, setLoading] = useState(false);

  if (pathname !== availableButton.path) {
    setAvailableButton({ available: false, path: pathname });
  }

  useEffect(() => {
    if (id) {
      executeEvent('viewProduct', {
        productDetail: {
          sku,
          name,
          brand_name: brandName,
          business,
          price
        },
        eventId
      });
    }
  }, [id]);

  const scrollHandler = () => {
    if (isDesktop) {
      if (
        refInfo.current.scrollTop >
        refInfo.current.scrollHeight - refInfo.current.clientHeight - 80
      ) {
        if (!hidden) {
          setHidden(true);
        }
      } else if (hidden) {
        setHidden(false);
      }
    }
  };
  const handleAddToCart = async () => {
    setLoading(true);
    const result = await api.addItem(variantSelected.id, 1, eventId, storeId);
    if (result.success) {
      dispatch(addToCart(result.data));
      executeEvent('addToCart', {
        productDetail: {
          sku,
          name,
          brand_name: brandName,
          business,
          price
        },
        eventId
      });
      // si alguna vez no te hace el scroll no te gastes en buscar el problema, no existe ( esta en tu mente)
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
      if (isModal) closeModal();
      dispatch(toggleCartModal(true));
    }
    setLoading(false);
  };

  const [CCmodal, setCCmodal] = useState(false);

  const toogleModal = () => {
    setCCmodal(!CCmodal);
  };
  const variantLogic =
    availableButton?.available && variantSelected?.product_id
      ? 'primary'
      : 'disabled';

  const clickLogic =
    variantSelected?.product_id.toString() === id &&
    variantSelected?.allow_click_and_collect?.length > 0;
  const handleClickShowMore = () => {
    if (closeModal) closeModal();
    document.cookie = `idColor=${idColor}; max-age=${7200}; path=/`;
  };

  const seeMoreDetails = () => {
    if (slug?.includes(id)) {
      return `/${slug}`;
    }
    return `/${slug}-${id}`;
  };

  return (
    <div
      className={styles.info}
      ref={refInfo}
      onScroll={scrollHandler}
    >
      {isModal && isDesktop && (
        <button
          id="close productInfoModal"
          className={styles.btn}
          onClick={closeModal}
          aria-label="close"
        >
          <Icon
            type="close"
            color="var(--color-white-absolute)"
            width="1rem"
          />
        </button>
      )}
      {!isDesktop && youTubeLink.length > 0 && (
        <button
          onClick={() => setShowYTVideo(youTubeLink[0])}
          style={{
            border: 'none',
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            alignSelf: 'center',
            gap: '8px',
            width: '100%',
            borderRadius: '9px',
            fontWeight: 700
          }}
        >
          <FaYoutube
            color="#FF0000"
            fontSize={46}
          />
          Review
        </button>
      )}
      {storeId !== 'city' && (
        <div style={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
          <Gender
            gender={gender}
            sport={sport}
          />
          {featured?.name && <Featured featured={featured} />}
        </div>
      )}
      <div
        className={styles.title}
        style={{ marginRight: isModal && isDesktop && '3rem' }}
      >
        <Name
          name={name}
          color={color}
        />
        {discount && storeId === 'city' && <Discount discount={discount} />}
      </div>
      <Sku sku={sku} />
      {storeId === 'city' && (
        <div style={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
          <Gender
            gender={gender}
            sport={sport}
          />
          {featured?.name && <Featured featured={featured} />}
        </div>
      )}
      {valorations.reviewsCount > 0 && (
        <PunctuationReview
          number={parseFloat(valorations.avgRating)}
          isControlled={false}
        />
      )}
      <div
        className={`${styles.prices} ${
          storeId === 'city' && styles.pricesCity
        }`}
      >
        <Price price={price} />
        {strikePrice && <StrikePrice strikePrice={strikePrice} />}
      </div>
      {installments.quantity > 0 && (
        <Installments installments={installments} />
      )}
      {cardPromosModal && (
        <CardPromosModal onClose={() => setCardPromoModal(false)} />
      )}
      <ColorSelector
        color={color}
        idColor={idColor}
        isModal={isModal}
        slug={slug}
        colors={colors}
        handleSetProduct={handleSetProduct}
      />
      {business && (
        <SizeSelector
          variants={variants}
          variantSelected={variantSelected}
          business={business}
          gender={gender}
          onClick={setAvailableButton}
          isModal={isModal}
        />
      )}
      {!isModal && <BankPromotions setCardPromoModal={setCardPromoModal} />}
      {clickLogic && (
        <div style={{ display: 'flex', marginBlock: '15px' }}>
          <div>
            <Text>
              Retiro GRATIS en sucursal{' '}
              <strong style={{ color: 'var(--color-primary' }}>
                ¡Retiralo YA!
              </strong>
            </Text>
            <Button
              withOutPadding
              width="none"
              label="Ver sucursales"
              onClick={toogleModal}
              style={{ height: 'auto' }}
            />
          </div>
          {CCmodal && (
            <GeneralModal
              closeColor="var(--color-primary)"
              onClose={toogleModal}
            >
              <ClickandCollect
                availableStores={variantSelected.allow_click_and_collect}
              />
            </GeneralModal>
          )}
        </div>
      )}
      {linkToDetail && (
        <div className={styles.linkToDetail}>
          <Link
            onContextMenu={handleClickShowMore}
            handleClick={handleClickShowMore}
            href={seeMoreDetails()}
          >
            <Text colored>Ver más detalles</Text>
          </Link>
        </div>
      )}
      {name && (
        <div className={styles.addToCartWrapper}>
          <Button
            label="Agregar al Carrito"
            variant={variantLogic}
            onClick={handleAddToCart}
            id="AddToCart"
            loading={loading}
          />
          <ShareButton title={name} />
        </div>
      )}
      {showYTVideo !== '' && (
        <GeneralModal
          onClose={() => setShowYTVideo('')}
          closeColor="white"
          modalStyles={{
            backgroundColor: 'white',
            width: '600px',
            height: '43%',
            maxHeight: '50.2vw',
            maxWidth: '90%',
            padding: 0
          }}
        >
          <iframe
            width="100%"
            height="100%"
            style={{ borderRadius: '30px' }}
            frameBorder={0}
            src={showYTVideo}
            title="Titulo"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          />
        </GeneralModal>
      )}
    </div>
  );
};

BankPromotions.propTypes = {
  setCardPromoModal: PropTypes.func.isRequired
};

Installments.propTypes = {
  installments: PropTypes.shape({
    quantity: PropTypes.number,
    value: PropTypes.string
  })
};
Installments.defaultProps = {
  installments: {}
};

Gender.propTypes = {
  gender: PropTypes.string.isRequired,
  sport: PropTypes.string
};
Gender.defaultProps = {
  sport: ''
};

Featured.propTypes = {
  featured: PropTypes.shape({
    background_color_left: PropTypes.string,
    background_color_right: PropTypes.string,
    background_color: PropTypes.string,
    name: PropTypes.string,
    text_color: PropTypes.string
  })
};
Featured.defaultProps = {
  featured: null
};

Discount.propTypes = {
  discount: PropTypes.number
};
Discount.defaultProps = {
  discount: ''
};

StrikePrice.propTypes = {
  strikePrice: PropTypes.string
};
StrikePrice.defaultProps = {
  strikePrice: ''
};

Price.propTypes = {
  price: PropTypes.string.isRequired
};

Name.propTypes = {
  name: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired
};

Sku.propTypes = {
  sku: PropTypes.string.isRequired
};

ProductInfo.propTypes = {
  color: PropTypes.string.isRequired,
  colors: PropTypes.array.isRequired,
  discount: PropTypes.number,
  gender: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  idColor: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  installments: PropTypes.shape({
    quantity: PropTypes.number,
    value: PropTypes.string
  }),
  sku: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  sport: PropTypes.string,
  strikePrice: PropTypes.string,
  linkToDetail: PropTypes.bool,
  variants: PropTypes.array,
  business: PropTypes.string.isRequired,
  isModal: PropTypes.bool,
  valorations: PropTypes.object,
  handleSetProduct: PropTypes.oneOfType([PropTypes.func, PropTypes.bool])
    .isRequired,
  closeModal: PropTypes.func.isRequired,
  brand_name: PropTypes.string.isRequired,
  featured: PropTypes.shape({
    background_color_left: PropTypes.string,
    background_color_right: PropTypes.string,
    background_color: PropTypes.string,
    name: PropTypes.string,
    text_color: PropTypes.string
  }),
  youTubeLink: PropTypes.array
};

export default ProductInfo;
