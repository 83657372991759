'use client';

/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import dynamic from 'next/dynamic';
import styles from './index.module.scss';
import ThumbnailIndicator from './ThumbnailIndicator';
import FavoriteButton from '@/components/organisms/FavoriteButton';
import { Button, Icon, Image } from '@/components/atoms';
import useDeviceType from '@/hooks/useDeviceType';
import validateVideoFile from '@/utils/validateVideoFile';

const ZoomCanvas = dynamic(() => import('@/components/organisms/ZoomCanvas'), {
  ssr: false
});

const imagesSkeleton = [
  { zoom: { url: '' }, thumbnail: { url: '' } },
  { zoom: { url: '' }, thumbnail: { url: '' } }
];

const ProductImageSlider = ({
  name,
  color,
  isFavorite,
  productId,
  images = imagesSkeleton,
  interval = 6000,
  automatic = true,
  isModal = false,
  closeModal = () => {},
  embeddeds = { youtube: [], render: [] }
}) => {
  const sortByVideos = [
    ...images.filter((i) => validateVideoFile(i?.thumbnail.ext)),
    ...images.filter((i) => !validateVideoFile(i?.thumbnail.ext))
  ];
  const [autoplay, setAutoplay] = useState(true);
  const [clicked, setClicked] = useState(false);
  const wrapper = useRef(null);
  const slider = useRef(null);
  const [indexImage, setIndexImage] = useState(0);
  const scrolling = useRef();
  const [showZoomModal, setShowZoomModal] = useState(false);
  const [imageSelected, setImageSelected] = useState('');

  const { isDesktop } = useDeviceType();

  const next = () => {
    if (!showZoomModal) {
      setIndexImage((prevIndexImage) => {
        if (prevIndexImage === sortByVideos?.length - 1) {
          slider.current?.scrollTo({
            left: 0,

            behavior: 'smooth'
          });
          return 0;
        }
        slider.current?.scrollTo({
          left: (prevIndexImage + 1) * slider.current?.clientWidth,
          behavior: 'smooth'
        });
        return prevIndexImage + 1;
      });
    }
  };
  useEffect(() => {
    setShowZoomModal(false);
  }, [images]);

  // setea el autoScroll
  useEffect(() => {
    if (automatic && true ? indexImage > 0 : true) {
      const autoScroll = setInterval(next, interval);
      // elimina el autoScroll
      if (!autoplay) {
        clearInterval(autoScroll);
      }
      return () => {
        clearInterval(autoScroll);
      };
    }

    return () => {};
  }, [autoplay, showZoomModal, indexImage]);

  const goToImage = (index, isScrolling) => {
    setAutoplay(false);
    if (!isScrolling) setClicked(true);
    slider.current?.scrollTo({
      left: index * slider.current?.clientWidth,
      behavior: 'smooth'
    });
    setIndexImage(index);
    if (!showZoomModal) {
      if (
        !validateVideoFile(sortByVideos[index]?.thumbnail.ext) ||
        isScrolling
      ) {
        setAutoplay(true);
      }
      setTimeout(() => {
        if (!isScrolling) setClicked(false);
      }, 10000);
    }
  };
  const onScroll = () => {
    if (scrolling) {
      clearTimeout(scrolling.current);
    }
    scrolling.current = setTimeout(() => {
      if (!clicked) {
        if (
          Math.floor(slider.current?.scrollLeft / slider.current?.clientWidth) %
            2 !==
          0
        ) {
          goToImage(
            Math.floor(
              slider.current?.scrollLeft / slider.current?.clientWidth
            ),
            true
          );
        }
        if (
          Math.ceil(slider.current?.scrollLeft / slider.current?.clientWidth) -
            slider.current?.scrollLeft / slider.current?.clientWidth <
          0.4
        ) {
          goToImage(
            Math.ceil(slider.current?.scrollLeft / slider.current?.clientWidth),
            true
          );
        }
      }
    }, 100);
  };

  const handleZoom = (image) => {
    if (isModal) return null;
    setShowZoomModal(true);
    setImageSelected(image);
  };
  const renderImage = (image, i) => {
    if (typeof image === 'string') {
      return (
        <iframe
          width="100%"
          height="92%"
          src={image}
          style={{ border: 'none' }}
          title="Modelo 3D"
        />
      );
    }
    return (
      <Image
        src={image.zoom?.url}
        alt={`Imagen ${i} de ${sortByVideos.length} de ${name}-${color}`}
        width={900}
        height={900}
        widthSkeleton="300px"
        heightSkeleton={isModal ? '400px' : ''}
        priority={i === 0}
        onClick={() => handleZoom(image.zoom?.url)}
      />
    );
  };
  return (
    <div
      className={styles.container}
      id="image-slider"
    >
      {isModal && !isDesktop && (
        <button
          className={styles.btn}
          onClick={closeModal}
          aria-label="close"
        >
          <Icon
            type="close"
            color="var(--color-grey-light)"
            width="1rem"
          />
        </button>
      )}
      <div
        className={styles.slider}
        onScroll={onScroll}
        ref={slider}
      >
        {showZoomModal ? (
          <>
            <button
              className={styles.btn}
              onClick={() => setShowZoomModal(false)}
              aria-label="close"
            >
              <Icon
                type="close"
                color="var(--color-grey-dark)"
                width="1rem"
              />
            </button>
            <ZoomCanvas
              imageSelected={imageSelected}
              stopAutoplay={() => setAutoplay(false)}
            />
          </>
        ) : (
          <div className={styles.sliderContainer}>
            {[...(embeddeds.render || []), ...sortByVideos]?.map((image, i) => (
              <div
                key={image.zoom?.url}
                className={styles.bannerWrapper}
                ref={wrapper}
              >
                <div className={styles.img}>
                  {validateVideoFile(image?.thumbnail?.ext) ? (
                    <video
                      style={{ width: '100%', height: '100%' }}
                      autoPlay
                      muted
                      onPlay={() => setAutoplay(false)}
                      onEnded={(e) => {
                        e.target.play();
                        next();
                        setAutoplay(true);
                      }}
                      className={styles.video}
                    >
                      <source
                        src={`${image?.thumbnail?.url}`}
                        type={`video/${image?.thumbnail?.ext}`}
                      />
                    </video>
                  ) : (
                    renderImage(image, i)
                  )}
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
      {!showZoomModal && (
        <div className={styles.favorite}>
          <FavoriteButton
            isFavorite={isFavorite}
            productId={productId}
            name={name}
          />
        </div>
      )}
      {!showZoomModal &&
        !isModal &&
        !(indexImage === 0 && embeddeds.render?.length > 0) &&
        !validateVideoFile(sortByVideos[indexImage]?.thumbnail.ext) && (
          <div className={styles.zoom}>
            <Button
              id="imaegZoom"
              variant="icon"
              withOutPadding
              onClick={() => handleZoom(sortByVideos[indexImage]?.zoom?.url)}
              startIcon={
                <Icon
                  type="zoom"
                  color="var(--color-grey)"
                />
              }
            />
          </div>
        )}
      {!showZoomModal && (
        <ThumbnailIndicator
          embeddeds={embeddeds}
          images={sortByVideos}
          goToImage={goToImage}
          indexImage={indexImage}
          showZoomModal={false}
        />
      )}
    </div>
  );
};

ProductImageSlider.propTypes = {
  images: PropTypes.array,
  interval: PropTypes.number,
  automatic: PropTypes.bool,
  name: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  isModal: PropTypes.bool,
  closeModal: PropTypes.func,
  isFavorite: PropTypes.bool.isRequired,
  productId: PropTypes.string.isRequired,
  embeddeds: PropTypes.shape({
    youtube: PropTypes.array.isRequired,
    render: PropTypes.array.isRequired
  })
};

export default ProductImageSlider;
