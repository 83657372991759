import { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import dynamic from 'next/dynamic';
import Icon from '@/components/atoms/Icon';
import styles from './shareButton.module.scss';
import Button from '@/components/atoms/Button';
import useOutsideClick from '@/hooks/useOutsideClick';

const Share = dynamic(() => import('@/components/atoms/Share'), { ssr: false });

const ShareButton = ({ title }) => {
  const shareRef = useRef();
  const [showOptions, setShowOptions] = useState('hide');
  const [animation, setAnimation] = useState('fadeIn');

  const hide = () => {
    setAnimation('fadeOut');
    setTimeout(() => setShowOptions('hide'), 200);
  };

  const show = () => {
    setShowOptions('show');
    setAnimation('fadeIn');
  };

  useOutsideClick(shareRef, hide, showOptions === 'show');

  return (
    <div
      ref={shareRef}
      style={{
        position: 'relative',
        ...(showOptions === 'show'
          ? { marginTop: '5rem' }
          : { marginTop: '2rem' })
      }}
    >
      <div className={styles.container}>
        <Button
          id="socialLinksButton"
          className={styles.button}
          withOutPadding
          variant="icon"
          startIcon={
            <Icon
              type="share"
              color="var(--color-black)"
            />
          }
          onClick={show}
        />
      </div>
      <Share
        showOptions={showOptions}
        animation={animation}
        hide={hide}
        title={title}
      />
    </div>
  );
};

ShareButton.propTypes = {
  title: PropTypes.string.isRequired
};

export default ShareButton;
