import { useRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import StoreBranchItem from '@/components/templates/Help/Help/StoreBranches/StoreBranchList/StoreBranchItem';
import { Button, Divider, Image } from '@/components/atoms';
import styles from './ClickandCollect.module.scss';
import { setActive } from '@/redux/map/mapReducer';

const ClickandCollect = ({ availableStores }) => {
  const stores = useSelector((state) => state.storebranches);
  const [pickUpStores, setPickUpStores] = useState([]);

  const { active } = useSelector((state) => state.map);
  const map = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    const pickUpStoresAux = [];
    Object.entries(stores)?.forEach((element) => {
      element[1]?.forEach((store) => {
        if (availableStores.includes(store.id)) pickUpStoresAux.push(store);
      });
    });

    setPickUpStores(pickUpStoresAux);
  }, []);

  useEffect(() => {
    if (pickUpStores.length > 0) {
      dispatch(
        setActive(
          pickUpStores[0].address.address1
            .toLowerCase()
            .concat(' ', pickUpStores[0].address.address_number, ' ', 'vaypol')
            .replace(/\s/g, '_')
            .replace(/í/g, 'i')
        )
      );
    }
  }, [pickUpStores]);

  return (
    <div className={styles.modalContainer}>
      <div className={styles.titles}>
        <Button
          withOutPadding
          width="none"
          label="Sucursales cercanas"
        />
        <Button
          withOutPadding
          width="none"
          label="Todas las sucursales"
        />
      </div>
      <div className={styles.info}>
        <div className={styles.list}>
          {pickUpStores?.map((store) => (
            <StoreBranchItem
              key={store.id}
              item={store}
            />
          ))}
        </div>
        <Divider
          vertical
          inherit
        />
        <div
          id="mapa"
          className={styles.content}
          ref={map}
        >
          {active && (
            <Image
              imageStyles={{ width: '97%', height: '10%' }}
              src={`/maps/${active}.png`}
              alt="mapa de ubicacion"
            />
          )}
        </div>
      </div>
    </div>
  );
};

ClickandCollect.propTypes = {
  availableStores: PropTypes.array.isRequired
};

export default ClickandCollect;
