import PropTypes from 'prop-types';
import { FiChevronDown } from 'react-icons/fi';
import cn from 'classnames';
import styles from './index.module.scss';

const Chevron = ({
  color = 'white',
  open = false,
  size = 23,
  timeOfRotate = '0s'
}) => {
  const chevronClass = cn({
    [styles.open]: open,
    [styles.closed]: !open
  });
  return (
    <FiChevronDown
      color={color}
      fontSize={size}
      className={chevronClass}
      style={{ transition: `${timeOfRotate} linear` }}
    />
  );
};

export default Chevron;
Chevron.propTypes = {
  color: PropTypes.string,
  open: PropTypes.bool,
  size: PropTypes.number,
  timeOfRotate: PropTypes.string
};
